import '../scss/styles.scss'

import {drawerToggle} from "./modules/drawerToggle";
import {topSlider} from "./modules/topSlider";
import {smoothScroll} from "./modules/smoothScroll";
// import {hideScrollHeader} from "./modules/hideScrollHeader";
// import {setFitHeight} from "./modules/setFillHeight";

window.addEventListener('load', () => {
  topSlider();
  drawerToggle();
  smoothScroll();
  // hideScrollHeader();
  // setFitHeight();
})