import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.min.css';

export function topSlider() {
  if (document.querySelector('.js-mv-slider')) {
    const mvSliderOption = {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    }
    const swiper = new Swiper('.mv-slider', mvSliderOption); // eslint-disable-line
  }
}